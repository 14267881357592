.PageTemplate {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  height: 93.0vh;
  h2 {
    margin: 0;
    font: {
      weight: normal;
      size: 18px;
    }
  }

  .header {
    flex: 0 0 auto;
    z-index: 2;
  }
  
  .contentRow {
    flex: 1 1 auto;
    min-height: 0;
    height: 100%;
    position: relative;
    padding-top: 16px;
    overflow: hidden;

    .nav {
      display: flex;
      z-index: 1;
      height: 100%;

      .icon {
        font: inherit !important;

        &::before {
          font-family: 'remixicon' !important;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      padding: 10px 54px;
      overflow-y: auto;
      max-height: 100%;
      height: 100%;
      z-index: 0;
    }
  }
}
