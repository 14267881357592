@import '../../styles/config';

.AsideNavigationItem {
  font: {
    size: 14px;
    weight: 300;
  }

  &:not(.active) {
    color: $primary-text-secondary;

    .trigger {
      transition: opacity ease 0.2s;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &.active {
    font-weight: 600;
    color: $primary-text;
    cursor: default;
  }

  .trigger {
    display: flex;
    padding: 14px 20px;
    outline: none;

    &::before {
      font-size: 20px;
      min-width: 32px;
      text-align: left;
    }

    .label {
      flex: 1 1 auto;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }

  nav > & {
    & > .trigger {
      padding: 18px 20px;
    }
  }

  &.dropdown {
    $dropdown-transition-duration: 0.5s;

    & > .trigger {
      @extend %kloosterboer-icons;
      @extend %icon-arrow-drop-down-fill-after;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &::after {
        font-size: 20px;
        transition: transform ease $dropdown-transition-duration;
      }
    }

    & > .items {
      padding-left: 32px;
      max-height: 0;
      overflow: hidden;
      transition: max-height ease $dropdown-transition-duration;
    }

    &.open,
    &:hover {
      .trigger {
        &::after {
          transform: rotate(-180deg);
        }
      }

      & > .items {
        max-height: 300px;
      }
    }
  }
}
