@import './color.config.styles.scss';

%background {
  &::before {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: {
      color: $primary-background-color;
      image: url('../../../../public/images/lineage-logo-small-3.svg');
      repeat: no-repeat;
      position: bottom right;
      size: contain;
    }
  }
}

%hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
