* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

html,
body,
#root,
main {
  margin: 0;
  padding: 0;
  height: 100%;
}

main {
  display: flex;
}