$primary: #0065a0;
$secondary: #e3e3e3;
$secondary-lighter: #f3f3f3;
$secondary-lightest: #ffffff;
$primary-text: #ffffff;
$primary-text-secondary: #c7d9ff;
$secondary-text: #4e6880;
$secondary-text-light: #a5c5e2;
$primary-highlight: #f4921d;
$primary-success: #8ddb23;
$primary-error: #f4251d;
$primary-background-color: #f1f5ff;
$secondary-background-color: #e8eefd;
$primary-success-text-dark: #65af00;

$progressRed: $primary-error;
$progressGreen: $primary-success;
$progressOrange: $primary-highlight;

$primary-box-shadow: #00000033;
$primary-background-box-shadow: #005180;
$primary-light-box-shadow: #6e7f924d;
$primary-light-border: #ffffff19;
$primary-highlight-box-shadow: #15244d40;
$secondary-box-shadow: #a2a7b273;
$secondary-box-shadow-dark: #6e7f924d;
$secondary-aside-box-shadow: #a2a7b226;
$button-box-shadow: #0000004d;
$primary-dark-overlay: #00000080;

$secondary-border: #a2a7b240;

$primary-text-gradient: #f5f6f7;

$primary-light: #009482;
$primary-light-gradient-start: #0a9887;
$primary-light-gradient-end: #005f53;

$primary-regular-gradient-end: #3384b3;
