.root {
  &#{&} {
    font: inherit;
    color: inherit;
    background: none;
    border: none;
    text: {
      align: inherit;
      decoration: inherit;
    }

    &:not(.triggerable),
    &.disabled {
      cursor: default;
    }
  }
}
