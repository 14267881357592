@charset "UTF-8";

@font-face {
  font-family: 'kloosterboer';
  src: url('./kloosterboer.eot');
  src: url('./kloosterboer.eot?#iefix') format('embedded-opentype'),
    url('./kloosterboer.woff') format('woff'),
    url('./kloosterboer.ttf') format('truetype'),
    url('./kloosterboer.svg#kloosterboer') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  content: attr(data-icon);
}

[data-icon],
[class^='icon-'],
[class*=' icon-'],
%kloosterboer-icons {
  &::before,
  &::after {
    font-family: 'kloosterboer' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.icon-information-fill:before {
  content: '\61';
}
.icon-account-circle-fill:before {
  content: '\62';
}
%icon-add-circle-fill,
.icon-add-circle-fill {
  &::before {
    content: '\63';
  }
}
%icon-arrow-drop-down-fill,
.icon-arrow-drop-down-fill {
  &::before {
    content: '\64';
  }
}
%icon-arrow-drop-down-fill-after {
  &::after {
    content: '\64';
  }
}
%icon-arrow-left-s-fill,
.icon-arrow-left-s-fill {
  &::before {
    content: '\65';
  }
}
.icon-arrow-right-s-fill:before {
  content: '\66';
}

%icon-article-fill,
.icon-article-fill {
  &::before {
    content: '\67';
  }
}

.icon-azure-active-directory-seeklogo:before {
  content: '\68';
}
.icon-calendar-2-fill:before {
  content: '\69';
}
.icon-calendar-event-fill:before {
  content: '\6a';
}
.icon-flag-2-fill:before {
  content: '\6c';
}
.icon-flag-2-line:before {
  content: '\6d';
}
.icon-home-2-fill:before {
  content: '\6e';
}
.icon-information-fill-1:before {
  content: '\6f';
}
.icon-logout-box-line:before {
  content: '\70';
}
.icon-map-2-fill:before {
  content: '\71';
}
.icon-more-fill:before {
  content: '\72';
}
.icon-share-box-fill:before {
  content: '\73';
}
.icon-share-forward-2-fill:before {
  content: '\74';
}
.icon-shut-down-line:before {
  content: '\75';
}
.icon-ticket-2-fill:before {
  content: '\76';
}
.icon-time-fill:before {
  content: '\77';
}
.icon-trailer:before {
  content: '\78';
}
.icon-server-fill:before {
  content: '\79';
}
.icon-crm:before {
  content: '\7a';
}
.icon-truck-loading:before {
  content: '\41';
}
.icon-clipboard-fill:before {
  content: '\42';
}

%icon-close-circle-fill,
.icon-close-circle-fill {
  &::before {
    content: '\43';
  }
}

.icon-close-fill:before {
  content: '\44';
}
.icon-container:before {
  content: '\45';
}
.icon-pallet:before {
  content: '\46';
}
.icon-pie-chart-2-fill:before {
  content: '\47';
}
.icon-question-answer-fill:before {
  content: '\48';
}
.icon-search-line:before {
  content: '\49';
}
.icon-filter-2-fill:before {
  content: '\6b';
}
.icon-printer-fill:before {
  content: '\4a';
}
.icon-download-2-fill:before {
  content: '\4b';
}
.icon-xls:before {
  content: '\4c';
}
.icon-pdf-1:before {
  content: '\4d';
}
.icon-csv:before {
  content: '\4e';
}
.icon-arrow-left-s-line:before {
  content: '\4f';
}
.icon-arrow-right-s-line:before {
  content: '\50';
}
