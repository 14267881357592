@import './config';
@import './init.styles.scss';

body {
  @extend %background;
  font: {
    family: Montserrat, sans-serif;
    weight: 300;
  }
  letter-spacing: 0;
}

.page {
  flex: 1 1 auto;
}

.input {
  padding: 12px;
  border: 0;
  outline: none;

  &[type='datetime-local'] {
    padding: 10px 12px;
  }
}

.button {
  padding: 12px 44px;
  border: 0;
  outline: none;
  border-radius: 0;
  background-color: $primary;
  color: $primary-text;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primary;
}
.circularProgress {
  color: $primary;
}
.logo-container {
  display: flex;

  .logo-portal {
    color: $primary;
    font-weight: 600;
    font-style: italic;
    font-size: 20px;
    margin-top: 7px;
    margin-left: 4px;
  }
}
