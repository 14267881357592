@import '../../styles/config';

$aside-width: 200px;
$aside-transition-duration: 0.4s;

.AsideNavigation {
  position: relative;
  width: $aside-width;

  display: flex;
  transition: margin-left ease $aside-transition-duration;

  &:not(.open) {
    margin-left: -$aside-width;

    .content {
      pointer-events: none;
    }
  }

  .toggle,
  .content {
    background-color: $primary;
    color: $primary-text;
    border: none;
  }

  .toggle {
    @extend %kloosterboer-icons;
    @extend %icon-arrow-left-s-fill;
    position: absolute;
    top: 0;
    right: (-33px + 7px);
    width: 33px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 5px 5px 0;
    padding: 0 0 0 7px;
    outline: none;
    cursor: pointer;
    transition: opacity ease 0.2s;

    &::before {
      font-size: 22px;
      pointer-events: none;
      transition: transform ease $aside-transition-duration;
    }

    &:hover {
      opacity: 0.95;
    }

    &:active {
      &::before {
        transform: rotate(180deg);
      }
    }
  }

  &:not(.open) {
    .toggle {
      z-index: 2;

      &::before {
        transform: rotate(180deg);
      }
    }
  }

  .content {
    flex: 1 1 auto;
    z-index: 1;
    display: flex;
    flex-direction: column;
    font-family: Montserrat, muli, sans-serif;
    border-radius: 0 5px 0 0;
    box-shadow: 3px 0px 10px $primary-box-shadow;
    transition: box-shadow ease $aside-transition-duration;
    overflow-y: hidden;
    background: {
      image: url('../../../../public/images/lineage-logo-small-3.svg');
      repeat: no-repeat;
      position: left 22px bottom 100px;
      size: 240px;
    }

    .nav {
      @extend %hide-scrollbar;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      padding: 20px 0;
      overflow-y: auto;
    }

    .footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 14px;
      color: $primary-text-secondary;
      font: {
        size: 10px;
        weight: 300;
      }

      .poweredBy {
        margin-top: 18px;
      }
    }
  }

  &:not(.open) {
    .content {
      box-shadow: none;
    }
  }
}
